import { ApiStatus } from '../../api/constants/ApiConstants'

const { success, error } = ApiStatus

export default {
  setHomeData: (state, payload) => {
    state.home = payload
    state.homeLoadedStatus = success
  },
  setHomeDataFailure: state => {
    state.home = {}
    state.homeLoadedStatus = error
  },
  setHomeDataLoadingStatus: (state, payload) => {
    state.homeLoadedStatus = payload
  }
}
