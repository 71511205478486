import { getHomeData } from '../../api/HomeApi'
import { ApiStatus } from '../../api/constants/ApiConstants'

export default {
  getHomeDataAction: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit('setHomeDataLoadingStatus', ApiStatus.progress)
      getHomeData(payload)
        .then(({ data }) => {
          if (data) {
            commit('setHomeData', data)
            resolve()
          } else {
            commit('setHomeDataFailure')
            resolve()
          }
        })
    })
  }
}
